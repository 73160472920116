import React from 'react';
import { useTranslation } from 'react-i18next';

const EventTableRow = ({ timeStamp, employeeId, employeeName,deviceData, deviceId, event, type }) => {
    const timestampNumber = Number(timeStamp); // Ensure id is a number
    const date = new Date(timestampNumber);
    const device = deviceData.find(d => d.cloverDeviceId === deviceId);
    const deviceU = device ? device.deviceName : 'Unknown'; // Default to 'Unknown Device' if not found
    let parsedEvent;
    // Attempt to parse the event as JSON
    try {
        parsedEvent = JSON.parse(event);

    } catch (e) {
        parsedEvent = null;
    }
    if (isNaN(date.getTime())) {
        // Handle invalid date
        return (
            <tr className="border-b border-gray-300">
                <td className="px-4 py-2 text-center">Invalid Date</td>
                <td className="px-4 py-2 text-center"></td>
                <td className="px-4 py-2 text-center"></td>
                <td className="px-4 py-2 text-center"></td>
                <td className="px-4 py-2 text-center"></td>
                <td className="px-4 py-2 text-center"></td>
                <td className="px-4 py-2 text-center"></td>
            </tr>
        );
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    // Format the time as HH:mm AM/PM
    const formattedTime = date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    });

    return (
        <tr className="border-b border-gray-300">
            <td className="px-4 py-2 text-center">  
                <strong>{formattedDate}</strong>
                <br />
                {formattedTime}
            </td>
            {/* <td className="px-4 py-2 text-center">{employeeId}</td> */}
            <td className="px-4 py-2 text-center">{employeeName}</td>
            <td className="px-4 py-2 text-center">
                {parsedEvent ? (
                    // Display specific fields from the JSON object
                    <>
                    <div><strong>Message : </strong>{parsedEvent.mess}</div>
                    <div><strong>ID : </strong>{parsedEvent.id}</div>
                    {/* Add more fields as needed */}
                    </>
                ) : (
                    // Display the event string as is
                    event
                )}
            </td>
            <td className="px-4 py-2 text-center">{deviceU}</td>

            <td className="px-4 py-2 text-center" title={deviceId}>
                {deviceId.split('-')[0]}...
            </td>
            {/* <td className="px-4 py-2 text-center">{deviceId}</td> */}
            <td className="px-4 py-2 text-center">{type}</td>
        </tr>
    );
};


const Table = ({ events, deviceData }) => {
    const hasData = events.length > 0;
    const { t } = useTranslation();

    function formatType(type){
        let formattedType = type;

        if (formattedType === "ORDER" ){
            formattedType = t('order')
        }else if(formattedType === "WEB_SRM"){
            formattedType = t('web_srm')
        }else if(formattedType === "CONNECTION"){
            formattedType = t('connection')
        }
        return formattedType;
    }

    return (
        <div className="table-container w-full">
            <table className="w-full bg-white border border-zinc-200">
                <thead className="sticky top-0 bg-white">
                    <tr className="bg-white/opacity-0">
                        <th className="w-auto bg-slate-100 border-t border-zinc-200 px-3 py-4">{t('date') + " / " + t('time')}</th>
                        {/* <th className="w-auto bg-slate-100 border-t border-zinc-200 px-3 py-4">{t('employeeId')}</th> */}
                        <th className="w-auto bg-slate-100 border-t border-zinc-200 px-3 py-4">{t('employee_name')}</th>
                        <th className="w-auto bg-slate-100 border-t border-zinc-200 px-3 py-4">{t('events')}</th>
                        <th className="w-auto bg-slate-100 border-t border-zinc-200 px-3 py-4">{t('devices')}</th>
                        <th className="w-auto bg-slate-100 border-t border-zinc-200 px-3 py-4">{t('device_id')}</th>
                        {/* <th className="w-auto bg-slate-100 border-t border-zinc-200 px-3 py-4">{t('web_srm_device_id')}</th> */}

                        <th className="w-auto bg-slate-100 border-t border-zinc-200 px-3 py-4">{t('type')}</th>
                    </tr>
                </thead>
                <tbody>
                    {hasData ? (
                        events.map((log, index) => (
                            <EventTableRow
                                key={index}
                                timeStamp={log.timeStamp}
                                employeeId={log.employee.cloverId}
                                employeeName={log.employee.name}
                                deviceData={deviceData}
                                deviceId={log.deviceId}
                                event={log.event}
                                type={formatType(log.type)}
                            />
                        ))
                    ) : (
                        <tr>
                            <td colSpan={6} className="px-4 py-2 text-center">{t('msg_no_events_available')}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default Table;
