import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const TransactionItemTable = ({ items }) => {
    const { t } = useTranslation();
    return (
        <div className="table-container w-full ">
            <table className="w-full bg-white border border-zinc-200 ">
                <thead className="sticky top-0 bg-white">
                    <tr className="bg-white/opacity-0">
                        <th className="w-[119px] bg-slate-100 border-t border-zinc-200 px-3 py-4">{t('description')}</th>
                        <th className="w-[119px] bg-slate-100 border-t border-zinc-200 px-3 py-4">{t('quantity')}</th>
                        <th className="w-[119px] bg-slate-100 border-t border-zinc-200 px-3 py-4">{t('price')}</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((item, index) => (
                        <tr key={index} className="border-b border-gray-300">
                            <td className="px-4 py-2 text-center">{item.descr}</td>
                            <td className="px-4 py-2 text-center">{parseInt(parseFloat(item.qte))}</td>
                            <td className="px-4 py-2 text-center">$ {parseFloat(item.prix).toFixed(2)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};
function formatTime(timeString) {
    const hours = parseInt(timeString.slice(0, 2), 10);
    const minutes = timeString.slice(2, 4);
    const seconds = timeString.slice(4, 6);

    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format and handle midnight (0 hours).

    return `${formattedHours}:${minutes} ${ampm}`;
}

const RestaurantInformation = ({ transactionData, deviceData }) => {
    const [showItems, setShowItems] = useState(false);
    const device = deviceData.find(d => d.cloverDeviceId === transactionData.cloverDeviceId);
    const deviceU = device ? device.deviceName : 'Unknown'; // Default to 'Unknown Device' if not found
    const timestampNumber = Number(transactionData.transactionId); // Ensure id is a number
    const date = new Date(timestampNumber);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    // Format the time as HH:mm AM/PM
    const formattedTime = date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    });
    const toggleItems = () => {
        setShowItems(!showItems);
    };
    const { t } = useTranslation();

    function formatBillType(billType) {

        // Remove "Receipt" from the string
        let formattedBillType = billType.replace("Receipt", "");

        // Add spaces before each uppercase letter, except the first one
        formattedBillType = formattedBillType.replace(/([A-Z])/g, ' $1').trim();

        if (formattedBillType.includes("Cancel")) {
            formattedBillType = formattedBillType.replace("Cancel", "Deleted");
        }

        return formattedBillType;
    }

    function formatPaymentMode(paymentMode) {
        let formattedPaymentMode = paymentMode;

        if (formattedPaymentMode === "ARG") {
            formattedPaymentMode = t('cash')
        } else if (formattedPaymentMode === "CRE") {
            formattedPaymentMode = t('credit')
        } else if (formattedPaymentMode === "DEB") {
            formattedPaymentMode = t('debit')
        } else {
            formattedPaymentMode = t('other')
        }
        return formattedPaymentMode;
    }

    return (
        <>
            <tr className="border-b border-gray-300">

                <td className="px-4 py-2 text-center">
                    <strong>{formattedDate}</strong>
                    <br />
                    {formattedTime}
                </td>
                <td className="px-4 py-2 text-center">{transactionData.transActu.noTrans}</td>
                <td className="px-4 py-2 text-center">{transactionData.orderId}</td>
                <td className="px-4 py-2 text-center">{transactionData.employee.name}</td>
                <td className="px-4 py-2 text-center"> ${parseFloat(transactionData.transActu.mont.apresTax).toFixed(2)}</td>
                <td className="px-4 py-2 text-center">{formatPaymentMode(transactionData.transActu.modPai)}</td>
                <td className="px-4 py-2 text-center">{deviceU}</td>
                <td className="px-4 py-2 text-center">{transactionData.idAprl}</td>
                <td className="px-4 py-2 text-center" title={transactionData.cloverDeviceId}>
                    {transactionData.cloverDeviceId.split('-')[0]}...
                </td>
                <td className="px-4 py-2 text-center">{formatBillType(transactionData.billType)}</td>
                <td className={`px-4 py-2 text-center ${transactionData.isOffline ? 'text-red-500' : 'text-green-500'}`}>
                    {transactionData.isOffline ? t('offline') :  t('online')}
                </td>
                <td className="px-4 py-2 text-center">
                    <button onClick={toggleItems} className="bg-sky-900 hover:bg-sky-700 text-white font-medium font-['Roboto', 'Arial', 'sans-serif'] py-2 px-4 rounded">
                        {showItems ? t('hide_items') : t('show_items')}
                    </button>
                </td>
            </tr>
            {showItems && transactionData.transActu && transactionData.transActu.items && transactionData.transActu.items.length > 0 && (
                <tr className="border-b border-gray-300">
                    <td colSpan="12">
                        <TransactionItemTable items={transactionData.transActu.items} />
                    </td>
                </tr>
            )}
        </>
    );
};

const TransactionTable = ({ data, deviceData }) => {
    const { t } = useTranslation();
    return (
        <div className="table-container w-full ">
            <table className="w-full bg-white border border-zinc-200 ">
                <thead className="sticky top-0 bg-white">
                    <tr className="bg-white/opacity-0">
                        <th className="w-auto bg-slate-100 border-t border-zinc-200 px-3 py-4">{t('date') + " / " + t('time')}</th>
                        <th className="w-auto bg-slate-100 border-t border-zinc-200 px-3 py-4">{t('noTrans')}</th>
                        <th className="w-auto bg-slate-100 border-t border-zinc-200 px-3 py-4">{t('order_id')}</th>
                        <th className="w-auto bg-slate-100 border-t border-zinc-200 px-3 py-4">{t('employees')}</th>
                        <th className="w-auto bg-slate-100 border-t border-zinc-200 px-3 py-4">{t('totalApT')}</th>
                        <th className="w-auto bg-slate-100 border-t border-zinc-200 px-3 py-4">{t('payment_mode')}</th>
                        <th className="w-auto bg-slate-100 border-t border-zinc-200 px-3 py-4">{t('devices')}</th>
                        <th className="w-auto bg-slate-100 border-t border-zinc-200 px-3 py-4">{t('web_srm_device_id')}</th>
                        <th className="w-auto bg-slate-100 border-t border-zinc-200 px-3 py-4">{t('clover_device_id')}</th>
                        <th className="w-auto bg-slate-100 border-t border-zinc-200 px-3 py-4">{t('bill_type')}</th>
                        <th className="w-auto bg-slate-100 border-t border-zinc-200 px-3 py-4">{t('connection_mode')}</th>
                        <th className="w-auto bg-slate-100 border-t border-zinc-200 px-3 py-4">{t('items')}</th>
                        <th className="grow shrink basis-0 bg-slate-100 border-t border-zinc-200 px-3 py-4"></th>
                    </tr>
                </thead>
                <tbody>
                    {data.length > 0 ? (
                        data.map(transaction => <RestaurantInformation key={transaction.id} transactionData={transaction} deviceData={deviceData} />)
                    ) : (
                        <tr>
                            <td colSpan={12} className="px-4 py-2 text-center">{t('msg_no_transactions_available')}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default TransactionTable;
