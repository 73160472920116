import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch, RouterProvider } from 'react-router-dom';
import { Navigate, Link, useLocation, useNavigate } from 'react-router-dom'
import { doSignInWithEmailAndPassword, doSignInWithGoogle, getVerifiedEmail } from '../../../firebase/auth'
import { useAuth } from '../../../contexts/authContext'
import '../../../index.css'; // Import the CSS file
import {
    doc,
    onSnapshot,
    query,
    updateDoc,
    getDocs,
    setDoc,
    deleteDoc,
    collection,
    where,
    limit,
    serverTimestamp,
    getDoc,
    orderBy,
} from 'firebase/firestore';
import db from '../../../firebase/firebase';
import Header from '../../header';
import { useTranslation } from 'react-i18next';

var currentUserRole = 'unknown'

export const setCurrentUserRole = (role) => {
    currentUserRole = role;
    localStorage.setItem('currentUserRole', role);
}

export const getCurrentUserRole = async () => {
    // Simulate an API call to fetch user role
    return new Promise((resolve) => {
        setTimeout(() => {
            const role = currentUserRole;
            resolve(role);
        }, 1000); // Simulate network delay
    });
};

const Login = () => {
    const { userLoggedIn } = useAuth()
    const location = useLocation();
    const navigate = useNavigate();

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isSigningIn, setIsSigningIn] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [isLoggedInMessage, setIsLoggedInMessage] = useState(false); // New state for logged in message
    const [disabledAccountMessage, setDisabledAccountMessage] = useState(''); // Define disabled account message state
    const [isVerified, setIsVerified] = useState(false); // State to handle the resending process
    // const [adminIsConnected, setAdminIsConnected] = useState(false)
    const { t } = useTranslation();

    const onSubmit = async (e) => {
        e.preventDefault();
        if (!isSigningIn) {
            setIsSigningIn(true);
            localStorage.clear();
            try {
                let disableSignIn = false; // Flag to disable sign-in if any user is not active

                const fetchData = async () => {
                    disableSignIn = false;
                    // const querySnapshotInactiveUser = await getDocs(query(collection(db, 'Lookup'), where('ownerEmail', '==', email), where('status', '==', "Not Active")));
                    const querySnapshotInactiveUser = await getDocs(query(collection(db, 'Users'), where('email', '==', email)));
                    const userDoc = querySnapshotInactiveUser.docs[0];
                    const userData = userDoc.data();
                    const cloverMerchantId = userData.merchantId;
                    const querySnapshotMevMandatarys = await getDocs(query(collection(db, 'MEVMandatarys'), where('cloverMerchantId', '==', cloverMerchantId), where('status', '==', "false")));

                    console.log("querySnapshotInactiveUser fetched from Firestore at the Login Inactive?:", querySnapshotInactiveUser.docs.length);

                    if (querySnapshotMevMandatarys.docs.length > 0) {
                        console.log("NOT ACTIVE")
                        disableSignIn = true; // Set flag to disable sign-in
                        setDisabledAccountMessage('Your account is not active. Please contact support.');
                        // disableSignIn = true; // Set flag to disable sign-in
                    }
                }

                const signInVerified = await doSignInWithEmailAndPassword(email, password);

                if (signInVerified.emailVerified) {
                    // Email is verified, proceed with sign-in
                    setIsVerified(true); // Set isVerified to true
                    // fetchData(); // Fetch additional data

                    const usersRef = collection(db, 'Users');
                    const q = query(usersRef, where('email', '==', email), where('role', '==', 'Admin'), limit(1));
                    const querySnapshot = await getDocs(q);
                    if (!querySnapshot.empty) {
                        console.log("Admin login successful");
                        // setAdminIsConnected(true)
                        setCurrentUserRole('admin')
                        navigate('/admin', { replace: true });
                    } else if(querySnapshot.empty) {
                        await fetchData();
                        if (!disableSignIn){
                            console.log("User login successful");
                            // setAdminIsConnected(false)
                            setCurrentUserRole('user')
                            navigate('/dashboard', { replace: true });
                        }
                    }
                } else {
                    // Email is not verified, display appropriate message or take action
                    setDisabledAccountMessage('Your email is not verified. Please verify your email.');
                }

            } catch (error) {
                setIsSigningIn(false);

                if (error.code === 'auth/user-disabled') {
                    setDisabledAccountMessage('Your account is not active. Please contact support.'); // Set message for disabled account
                } else if (error.code === 'auth/invalid-credential') {
                    setDisabledAccountMessage('Invalid email or password. Please check your credentials and try again.'); // Set message for disabled account
                } else if (error.message === "You must verify your email before signing in.") {
                    setDisabledAccountMessage('Your email is not verified. Please verify your email.');
                } else {
                    setErrorMessage(error.message); // Update error message state
                }
            }
        }
    };

    const onGoogleSignIn = (e) => {
        e.preventDefault()
        if (!isSigningIn) {
            setIsSigningIn(true)
            doSignInWithGoogle().catch(err => {
                setIsSigningIn(false)
            })
        }
    }

    return (
        <div className="w-full h-screen relative bg-neutral-50 backgroundLogin">
            <div className=" left-[620px] h-[624px] p-20 bg-white rounded-2xl border border-zinc-200 flex-col justify-center items-center gap-6 inline-flex">
                <div className="self-stretch h-[70px] pb-6 flex-col justify-start items-center gap-2 flex">
                    <div className="self-stretch h-[46px] flex-col justify-start items-center gap-2 flex">
                        <div className="self-stretch text-center text-sky-900 text-[42px] font-bold font-['Roboto', 'Arial', 'sans-serif'] leading-[46.20px]">{t('welcome')}</div>
                    </div>
                </div>
                {disabledAccountMessage && (
                    <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="alert">
                        <p className="font-bold">{disabledAccountMessage}</p>
                    </div>
                )}
                <form onSubmit={onSubmit} className="self-stretch h-[268px] flex-col justify-start items-center gap-4 flex">
                    <div className="self-stretch h-[76px] flex-col justify-start items-start gap-1 flex">
                        <label className="self-stretch text-sky-900 text-sm font-normal font-['Roboto', 'Arial', 'sans-serif'] leading-tight">{t('email')}</label>
                        <div className="self-stretch h-12 px-4 py-3 bg-slate-100 rounded-lg border-b border-neutral-300 justify-start items-center gap-2 inline-flex">
                            <input
                                type="email"
                                autoComplete='email'
                                required
                                value={email} onChange={(e) => { setEmail(e.target.value) }}
                                placeholder={t('email')}
                                className="grow shrink basis-0 bg-slate-100 text-zinc-500 text-base font-normal font-['Roboto', 'Arial', 'sans-serif'] leading-snug"
                            />
                        </div>
                    </div>
                    <div className="self-stretch h-[76px] flex-col justify-start items-start gap-1 flex">
                        <label className="self-stretch text-sky-900 text-sm font-normal font-['Roboto', 'Arial', 'sans-serif'] leading-tight">{t('password')}</label>
                        <div className="self-stretch h-12 px-4 py-3 bg-slate-100 rounded-lg border-b border-neutral-300 justify-start items-center gap-2 inline-flex">
                            <input
                                type="password"
                                autoComplete='current-password'
                                required
                                value={password} onChange={(e) => { setPassword(e.target.value) }}
                                placeholder={t('password')}
                                className="grow shrink basis-0 bg-slate-100 text-zinc-500 text-base font-normal font-['Roboto', 'Arial', 'sans-serif'] leading-snug"
                            />
                            <div className="w-6 h-6 relative"></div>
                        </div>
                    </div>

                    <div className="self-stretch justify-center items-center gap-4 inline-flex">
                        <button
                            type="submit"
                            disabled={isSigningIn && isVerified}
                            className={`grow shrink basis-0 h-12 px-3 py-4 bg-sky-900 rounded-lg border-2 border-sky-900 justify-center items-center flex ${isSigningIn ? 'bg-gray-300 cursor-not-allowed' : 'bg-indigo-600 hover:bg-sky-900 hover:shadow-xl transition duration-300'}`}
                        >
                            <div className="px-4 justify-center items-center gap-2.5 flex">
                                <span className="text-white text-base font-medium font-['Roboto', 'Arial', 'sans-serif'] leading-none tracking-wide">{t('sign_in')}</span>

                            </div>
                        </button>
                    </div>
                </form>

                <div className="self-stretch h-[0px] border border-zinc-200"></div>
                <div className="self-stretch text-center text-sky-900 text-sm font-normal font-['Roboto', 'Arial', 'sans-serif'] leading-tight">{t('msg_account')}</div>
                <p className="self-stretch text-center text-sky-900 text-sm font-normal font-['Roboto', 'Arial', 'sans-serif'] leading-tight">{t('msg_forgot_password')} <Link to={'/reset'} className="hover:underline font-bold">{t('reset_password')}</Link></p>
            </div>
        </div>
    )
}

export default Login