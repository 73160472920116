import React, { useState, useEffect, Fragment, useRef, useLayoutEffect } from 'react';
import Header from "../header";
import { useNavigate } from 'react-router-dom'
import RestaurantTable from './RestaurantTable'; // Adjust the path as needed
import {
    query,
    getDocs,
    collection,
    addDoc,
} from 'firebase/firestore';
import db from '../../firebase/firebase';
import { useTranslation } from 'react-i18next';

function AdminHomepage() {

    const navigate = useNavigate();
    const currentUserRole = localStorage.getItem('currentUserRole')
    if (currentUserRole !== 'admin') {
        navigate('/accessDenied', { replace: true });
    }
    const { t } = useTranslation();

    const [isMobile, setIsMobile] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(t('restaurants'));
    const [searchTerm, setSearchTerm] = useState('');
    const [businessNames, setBusinessNames] = useState([]);
    const [mevMandatarys, setMevMandatarys] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const today = new Date();
    const [formData, setFormData] = useState({
        activitySubSector: '',
        authorizationCode: '',
        billingFileNo: '',
        businessName: '',
        city: '',
        cloverMerchantId: '',
        createdDate: today,
        identificationNo: '',
        lastModifiedDate: today,
        phone: '',
        postalCode: '',
        province: '',
        status: false,
        street1: '',
        street2: '',
        tpsNo: '',
        tvqNo: ''
        // Add all 17 fields here
    });

    useLayoutEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        // Function to fetch BusinessNames from MEVMandatorys collection
        const fetchBusinessNames = async () => {
            try {
                const querySnapshot = await getDocs(query(collection(db, "MEVMandatarys")));
                console.log("Query SnapShot MEVMANDATORY fetched from Firestore:", querySnapshot.docs.length);

                const mevMandatarysData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                let businessNamesData = [];
                mevMandatarysData.forEach((item, index) => {
                    businessNamesData.push(item.businessName)
                });

                localStorage.setItem('mevMandatarysData', JSON.stringify(mevMandatarysData));
                setMevMandatarys(mevMandatarysData)

                // const businessNamesData = querySnapshot.docs.map(doc => doc.data().businessName);
                setBusinessNames(businessNamesData);
                localStorage.setItem('businessData', JSON.stringify(businessNamesData));

            } catch (error) {
                console.error('Error fetching business names:', error);
            }
        };

        const storedBusinessData = localStorage.getItem('businessData');
        const mevMandatarysData = localStorage.getItem('mevMandatarysData');

        if (!storedBusinessData) {
            fetchBusinessNames();
        } else {
            const parsedBusinessData = JSON.parse(storedBusinessData);
            const parsedMevMandatarysData = JSON.parse(mevMandatarysData);
            setBusinessNames(parsedBusinessData);
            setMevMandatarys(parsedMevMandatarysData)
        }
        // Clean-up function to unsubscribe when component unmounts
        return () => { };
    }, []);


    const filteredOptions = [...businessNames].filter(option =>
        option.toLowerCase().includes(searchTerm.toLowerCase())
    );
    filteredOptions.unshift("Restaurants");

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
        setFormData((prevData) => ({
            ...prevData,
            [name]: newValue,
        }));
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            await addDoc(collection(db, 'MEVMandatarys'), formData);
            setShowForm(false);

            // Optionally, clear the form data
            setFormData({
                activitySubSector: '',
                authorizationCode: '',
                billingFileNo: '',
                businessName: '',
                city: '',
                cloverMerchantId: '',
                createdDate: today,
                identificationNo: '',
                lastModifiedDate: today,
                phone: '',
                postalCode: '',
                province: '',
                status: false,
                street1: '',
                street2: '',
                tpsNo: '',
                tvqNo: ''
                // Reset all 17 fields here
            });
        } catch (error) {
            console.error('Error adding document: ', error);
        }
    };

    useEffect(() => {
        if ( window.location.pathname !== "/admin") {
            navigate('/error404')
        }else{
            localStorage.setItem('lastValidPath', window.location.pathname);
        }
    }, [ navigate]);

    // Your table JSX
    return (
        <Fragment>
            {isMobile && (

                <div className="w-full bg-stone-50 justify-start items-start inline-flex flex-col">
                    <Header isAdmin={true} />

                    <div className="w-full  p-4" style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>

                        <div className="w-full bg-white justify-start items-center gap-6 inline-flex flex-col">
                            <div className="self-stretch w-full h-16 pl-4 pr-6 bg-white justify-between items-center inline-flex">
                                <div className="text-sky-900 text-xl font-bold leading-10 pr-4">
                                    {selectedOption}
                                </div>
                                <button
                                    className="h-10 px-4 rounded-lg border-2 border-sky-900 justify-center items-center flex"
                                    onClick={() => setShowForm(!showForm)}
                                >
                                    <div className="text-sky-900 text-base font-medium leading-none tracking-wide">{t('add_restaurant')}</div>
                                    <div className="text-sky-900 text-xl font-medium leading-7">+</div>
                                </button>
                            </div>
                            {showForm && (
                                <form onSubmit={handleFormSubmit} className="w-full px-4 py-6 bg-white flex flex-col justify-start items-start">

                                    <div className="mb-4 flex justify-between w-full">
                                        <div className="w-[33%]">
                                            <label htmlFor="activitySubSector" className="block text-gray-700">{t('activity_sub_sector')}</label>
                                            <input
                                                type="text"
                                                id="activitySubSector"
                                                name="activitySubSector"
                                                value={formData.activitySubSector}
                                                onChange={handleInputChange}
                                                placeholder={t('activity_sub_sector')}

                                                required
                                                className="mt-1 p-2 w-full border rounded-lg"
                                            />
                                        </div>
                                        <div className="w-[33%]">
                                            <label htmlFor="authorizationCode" className="block text-gray-700">{t('authorization_code')}</label>
                                            <input
                                                type="text"
                                                id="authorizationCode"
                                                name="authorizationCode"
                                                value={formData.authorizationCode}
                                                onChange={handleInputChange}
                                                required
                                                className="mt-1 p-2 w-full border rounded-lg"
                                            />
                                        </div>
                                        <div className="w-[33%]">
                                            <label htmlFor="billingFileNo" className="block text-gray-700">{t('billing_file_number')}</label>
                                            <input
                                                type="text"
                                                id="billingFileNo"
                                                name="billingFileNo"
                                                value={formData.billingFileNo}
                                                onChange={handleInputChange}
                                                required
                                                className="mt-1 p-2 w-full border rounded-lg"
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-4 flex justify-between w-full">
                                        <div className="w-[48%]">
                                            <label htmlFor="businessName" className="block text-gray-700">{t('business_name')}</label>
                                            <input
                                                type="text"
                                                id="businessName"
                                                name="businessName"
                                                value={formData.businessName}
                                                onChange={handleInputChange}
                                                required
                                                className="mt-1 p-2 w-full border rounded-lg"
                                            />
                                        </div>
                                        <div className="w-[48%]">
                                            <label htmlFor="cloverMerchantId" className="block text-gray-700">{t('clover_merchant_id')}</label>
                                            <input
                                                type="text"
                                                id="cloverMerchantId"
                                                name="cloverMerchantId"
                                                value={formData.cloverMerchantId}
                                                onChange={handleInputChange}
                                                required
                                                className="mt-1 p-2 w-full border rounded-lg"
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-4 flex justify-between w-full">
                                        <div className="w-[48%]">
                                            <label htmlFor="identificationNo" className="block text-gray-700">{t('identification_number')}</label>
                                            <input
                                                type="text"
                                                id="identificationNo"
                                                name="identificationNo"
                                                value={formData.identificationNo}
                                                onChange={handleInputChange}
                                                required
                                                className="mt-1 p-2 w-full border rounded-lg"
                                            />
                                        </div>
                                        <div className="w-[48%]">
                                            <label htmlFor="phone" className="block text-gray-700">{t('phone_number')}</label>
                                            <input
                                                type="text"
                                                id="phone"
                                                name="phone"
                                                value={formData.phone}
                                                onChange={handleInputChange}
                                                required
                                                className="mt-1 p-2 w-full border rounded-lg"
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-4 flex justify-between w-full">
                                        <div className="w-[33%]">
                                            <label htmlFor="street1" className="block text-gray-700">{t('street_no')}</label>
                                            <input
                                                type="text"
                                                id="street1"
                                                name="street1"
                                                value={formData.street1}
                                                onChange={handleInputChange}
                                                required
                                                className="mt-1 p-2 w-full border rounded-lg"
                                            />
                                        </div>
                                        <div className="w-[33%]">
                                            <label htmlFor="street2" className="block text-gray-700">{t('street_name')}</label>
                                            <input
                                                type="text"
                                                id="street2"
                                                name="street2"
                                                value={formData.street2}
                                                onChange={handleInputChange}
                                                required
                                                className="mt-1 p-2 w-full border rounded-lg"
                                            />
                                        </div>
                                        <div className="w-[33%]">
                                            <label htmlFor="city" className="block text-gray-700">{t('city')}</label>
                                            <input
                                                type="text"
                                                id="city"
                                                name="city"
                                                value={formData.city}
                                                onChange={handleInputChange}
                                                required
                                                className="mt-1 p-2 w-full border rounded-lg"
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-4 flex justify-between w-full">
                                        <div className="w-[48%]">
                                            <label htmlFor="province" className="block text-gray-700">{t('province')}</label>
                                            <input
                                                type="text"
                                                id="province"
                                                name="province"
                                                value={formData.province}
                                                onChange={handleInputChange}
                                                required
                                                className="mt-1 p-2 w-full border rounded-lg"
                                            />
                                        </div>
                                        <div className="w-[48%]">
                                            <label htmlFor="postalCode" className="block text-gray-700">{t('postal_code')}</label>
                                            <input
                                                type="text"
                                                id="postalCode"
                                                name="postalCode"
                                                value={formData.postalCode}
                                                onChange={handleInputChange}
                                                required
                                                className="mt-1 p-2 w-full border rounded-lg"
                                            />
                                        </div>
                                    </div>

                                    <div className="mb-4 flex justify-between w-full">
                                        <div className="w-[48%]">
                                            <label htmlFor="tpsNo" className="block text-gray-700">{t('tps_number')}</label>
                                            <input
                                                type="text"
                                                id="tpsNo"
                                                name="tpsNo"
                                                value={formData.tpsNo}
                                                onChange={handleInputChange}
                                                required
                                                className="mt-1 p-2 w-full border rounded-lg"
                                            />
                                        </div>
                                        <div className="w-[48%]">
                                            <label htmlFor="tvqNo" className="block text-gray-700">{t('tvq_number')}</label>
                                            <input
                                                type="text"
                                                id="tvqNo"
                                                name="tvqNo"
                                                value={formData.tvqNo}
                                                onChange={handleInputChange}
                                                required
                                                className="mt-1 p-2 w-full border rounded-lg"
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="status" className="block text-gray-700">{t('status')}</label>
                                        <input
                                            type="checkbox"
                                            id="status"
                                            name="status"
                                            checked={formData.status}
                                            onChange={handleInputChange}
                                            className="mt-1 p-2 border rounded-lg"
                                        />
                                    </div>
                                    {/* Repeat for all 17 fields */}
                                    <button type="submit" className="mt-4 bg-sky-900 text-white px-4 py-2 rounded-lg">{t('submit')}</button>
                                    {/* Your form inputs go here */}
                                    <button type="submit" className="mt-4 bg-sky-900 text-white px-4 py-2 rounded-lg w-full">{t('submit')}</button>
                                </form>
                            )}
                        </div>

                        {/* Wrap the RestaurantTableMobile in a container with overflow styling */}
                        <div className="w-full  bg-white  overflow-x-auto">
                            <RestaurantTable mevMandatarysData={mevMandatarys} selectedBusinessName={selectedOption} />
                        </div>

                    </div>
                </div>


            )}
            {/* Content for desktop view */}
            {!isMobile && (
                <>
                    <div className="w-full h-[1080px] bg-stone-50 justify-start items-start inline-flex">

                        <Header isAdmin={true} />

                        <div className="grow shrink basis-0 flex-col justify-start items-start gap-6 inline-flex" style={{ paddingLeft: '15rem' }}>
                            <div className="self-stretch h-[118px] pl-[17px] pr-[49px] bg-white justify-start items-center gap-6 inline-flex">
                                <div className="grow shrink basis-0 flex-col justify-start items-start gap-12 inline-flex">
                                    <div className="self-stretch h-[70px] flex-col justify-start items-start gap-2 flex">
                                        <div
                                            className="w-[324px] h-[70px] bg-white rounded-lg justify-center items-center gap-[19px] inline-flex relative cursor-pointer"
                                            onClick={() => setIsOpen(!isOpen)}
                                        >
                                            <div className="text-sky-900 text-[42px] font-bold font-['Roboto', 'Arial', 'sans-serif'] leading-[46.20px] pr-10">
                                                {selectedOption}
                                            </div>
                                            <div className="absolute right-3 pointer-events-none flex items-center">
                                                <svg className="w-6 h-6 text-sky-900" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                                                </svg>
                                            </div>
                                            {isOpen && (
                                                <div className="absolute top-full mt-1 w-[328px] p-2.5 bg-white shadow rounded-lg flex-col justify-start items-start gap-2.5 inline-flex z-10">
                                                    <div className="self-stretch h-60 flex-col justify-start items-start flex overflow-y-auto">
                                                        <div className="self-stretch bg-slate-100 justify-start items-center inline-flex">
                                                            <div className="grow shrink basis-0 h-12 px-4 py-3 bg-slate-100 border-b border-neutral-300 justify-start items-center gap-2 flex">
                                                                <input
                                                                    type="text"
                                                                    className="w-full text-zinc-500 text-base font-normal font-['Roboto'] leading-snug bg-transparent border-none outline-none"
                                                                    placeholder={t('search')}
                                                                    value={searchTerm}
                                                                    onChange={(e) => setSearchTerm(e.target.value)}
                                                                    onClick={(e) => e.stopPropagation()} // Add this line to stop event propagation
                                                                />
                                                            </div>
                                                        </div>
                                                        {filteredOptions.map((option, index) => (
                                                            <div
                                                                key={index}
                                                                className="self-stretch justify-start items-center inline-flex cursor-pointer"
                                                                onClick={() => {
                                                                    setSelectedOption(option);
                                                                    setIsOpen(false);
                                                                }}
                                                            >
                                                                <div className="grow shrink basis-0 h-12 px-4 py-3 bg-white border-b border-neutral-300 justify-start items-center gap-2 flex">
                                                                    <div className="grow shrink basis-0 text-zinc-500 text-base font-normal font-['Roboto'] leading-snug">{option}</div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="w-[371px] h-12 justify-center items-center gap-4 flex">
                                    <button
                                        className="grow shrink basis-0 h-12 justify-center items-center gap-4 flex"
                                        onClick={() => setShowForm(!showForm)}
                                    >
                                        <div className="grow shrink basis-0 h-12 px-3 py-4 rounded-lg border-2 border-sky-900 justify-center items-center flex">
                                            <div className="px-4 justify-center items-center gap-2.5 flex">
                                                <div className="text-sky-900 text-base font-medium font-['Roboto', 'Arial', 'sans-serif'] leading-none tracking-wide">{t('add_restaurant')}</div>
                                            </div>
                                            <div className="px-1 justify-center items-center flex">
                                                <div className="text-sky-900 text-[28.09px] font-medium font-['Roboto', 'Arial', 'sans-serif'] leading-7 tracking-wide">+</div>
                                            </div>
                                        </div>
                                    </button>
                                </div>
                            </div>
                            {showForm && (

                                <form onSubmit={handleFormSubmit} className="w-full h-auto px-8 py-6 bg-white flex-col justify-start items-start inline-flex">
                                    <div className="mb-4 flex justify-between w-full">
                                        <div className="w-[33%]">
                                            <label htmlFor="activitySubSector" className="block text-gray-700">{t('activity_sub_sector')}</label>
                                            <input
                                                type="text"
                                                id="activitySubSector"
                                                name="activitySubSector"
                                                value={formData.activitySubSector}
                                                onChange={handleInputChange}
                                                required
                                                className="mt-1 p-2 w-full border rounded-lg"
                                            />
                                        </div>
                                        <div className="w-[33%]">
                                            <label htmlFor="authorizationCode" className="block text-gray-700">{t('authorization_code')}</label>
                                            <input
                                                type="text"
                                                id="authorizationCode"
                                                name="authorizationCode"
                                                value={formData.authorizationCode}
                                                onChange={handleInputChange}
                                                required
                                                className="mt-1 p-2 w-full border rounded-lg"
                                            />
                                        </div>
                                        <div className="w-[33%]">
                                            <label htmlFor="billingFileNo" className="block text-gray-700">{t('billing_file_number')}</label>
                                            <input
                                                type="text"
                                                id="billingFileNo"
                                                name="billingFileNo"
                                                value={formData.billingFileNo}
                                                onChange={handleInputChange}
                                                required
                                                className="mt-1 p-2 w-full border rounded-lg"
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-4 flex justify-between w-full">
                                        <div className="w-[48%]">
                                            <label htmlFor="businessName" className="block text-gray-700">{t('business_name')}</label>
                                            <input
                                                type="text"
                                                id="businessName"
                                                name="businessName"
                                                value={formData.businessName}
                                                onChange={handleInputChange}
                                                required
                                                className="mt-1 p-2 w-full border rounded-lg"
                                            />
                                        </div>
                                        <div className="w-[48%]">
                                            <label htmlFor="cloverMerchantId" className="block text-gray-700">{t('clover_merchant_id')}</label>
                                            <input
                                                type="text"
                                                id="cloverMerchantId"
                                                name="cloverMerchantId"
                                                value={formData.cloverMerchantId}
                                                onChange={handleInputChange}
                                                required
                                                className="mt-1 p-2 w-full border rounded-lg"
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-4 flex justify-between w-full">
                                        <div className="w-[48%]">
                                            <label htmlFor="identificationNo" className="block text-gray-700">{t('identification_number')}</label>
                                            <input
                                                type="text"
                                                id="identificationNo"
                                                name="identificationNo"
                                                value={formData.identificationNo}
                                                onChange={handleInputChange}
                                                required
                                                className="mt-1 p-2 w-full border rounded-lg"
                                            />
                                        </div>
                                        <div className="w-[48%]">
                                            <label htmlFor="phone" className="block text-gray-700">{t('phone_number')}</label>
                                            <input
                                                type="text"
                                                id="phone"
                                                name="phone"
                                                value={formData.phone}
                                                onChange={handleInputChange}
                                                required
                                                className="mt-1 p-2 w-full border rounded-lg"
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-4 flex justify-between w-full">
                                        <div className="w-[33%]">
                                            <label htmlFor="street1" className="block text-gray-700">{t('street_no')}</label>
                                            <input
                                                type="text"
                                                id="street1"
                                                name="street1"
                                                value={formData.street1}
                                                onChange={handleInputChange}
                                                required
                                                className="mt-1 p-2 w-full border rounded-lg"
                                            />
                                        </div>
                                        <div className="w-[33%]">
                                            <label htmlFor="street2" className="block text-gray-700">{t('street_name')}</label>
                                            <input
                                                type="text"
                                                id="street2"
                                                name="street2"
                                                value={formData.street2}
                                                onChange={handleInputChange}
                                                required
                                                className="mt-1 p-2 w-full border rounded-lg"
                                            />
                                        </div>
                                        <div className="w-[33%]">
                                            <label htmlFor="city" className="block text-gray-700">{t('city')}</label>
                                            <input
                                                type="text"
                                                id="city"
                                                name="city"
                                                value={formData.city}
                                                onChange={handleInputChange}
                                                required
                                                className="mt-1 p-2 w-full border rounded-lg"
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-4 flex justify-between w-full">
                                        <div className="w-[48%]">
                                            <label htmlFor="province" className="block text-gray-700">{t('province')}</label>
                                            <input
                                                type="text"
                                                id="province"
                                                name="province"
                                                value={formData.province}
                                                onChange={handleInputChange}
                                                required
                                                className="mt-1 p-2 w-full border rounded-lg"
                                            />
                                        </div>
                                        <div className="w-[48%]">
                                            <label htmlFor="postalCode" className="block text-gray-700">{t('postal_code')}</label>
                                            <input
                                                type="text"
                                                id="postalCode"
                                                name="postalCode"
                                                value={formData.postalCode}
                                                onChange={handleInputChange}
                                                required
                                                className="mt-1 p-2 w-full border rounded-lg"
                                            />
                                        </div>
                                    </div>

                                    <div className="mb-4 flex justify-between w-full">
                                        <div className="w-[48%]">
                                            <label htmlFor="tpsNo" className="block text-gray-700">{t('tps_number')}</label>
                                            <input
                                                type="text"
                                                id="tpsNo"
                                                name="tpsNo"
                                                value={formData.tpsNo}
                                                onChange={handleInputChange}
                                                required
                                                className="mt-1 p-2 w-full border rounded-lg"
                                            />
                                        </div>
                                        <div className="w-[48%]">
                                            <label htmlFor="tvqNo" className="block text-gray-700">{t('tvq_number')}</label>
                                            <input
                                                type="text"
                                                id="tvqNo"
                                                name="tvqNo"
                                                value={formData.tvqNo}
                                                onChange={handleInputChange}
                                                required
                                                className="mt-1 p-2 w-full border rounded-lg"
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="status" className="block text-gray-700">{t('status')}</label>
                                        <input
                                            type="checkbox"
                                            id="status"
                                            name="status"
                                            checked={formData.status}
                                            onChange={handleInputChange}
                                            className="mt-1 p-2 border rounded-lg"
                                        />
                                    </div>
                                    {/* Repeat for all 17 fields */}
                                    <button type="submit" className="mt-4 bg-sky-900 text-white px-4 py-2 rounded-lg">{t('submit')}</button>
                                </form>

                            )}
                            <RestaurantTable mevMandatarysData={mevMandatarys} selectedBusinessName={selectedOption} />
                        </div>
                    </div>

                </>
            )}
        </Fragment>
    );
}

export default AdminHomepage;
